"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_NAME = exports.OTP_CODE_LENGTH = exports.DEFAULT_PER_PAGE = exports.DEFAULT_PAGE = exports.PASSWORD_MAX_LENGTH = exports.PASSWORD_MIN_LENGTH = void 0;
__exportStar(require("./breakpoints"), exports);
__exportStar(require("./config"), exports);
__exportStar(require("./deals"), exports);
__exportStar(require("./enum"), exports);
__exportStar(require("./files"), exports);
__exportStar(require("./http"), exports);
__exportStar(require("./reasons"), exports);
__exportStar(require("./reports"), exports);
__exportStar(require("./tasks"), exports);
__exportStar(require("./users"), exports);
exports.PASSWORD_MIN_LENGTH = 8;
exports.PASSWORD_MAX_LENGTH = 50;
exports.DEFAULT_PAGE = 1;
exports.DEFAULT_PER_PAGE = 15;
/**
 * The number of digits/characters to use for one=time passwords.
 */
exports.OTP_CODE_LENGTH = 8;
exports.APP_NAME = 'ReportBook';
