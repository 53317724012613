import { toast } from 'react-toastify'
import {
  AffiliateReportRow,
  CustomerReportRow,
  ReportRetentionRatios
} from '@packages/types'
import { AxiosResponse } from 'axios'
import {
  CurrencyFormatOptions,
  formatCurrency
} from 'business/shared/helpers/currency'
import { formatDate, formatDateTime } from 'business/shared/helpers/date'
import withToast from 'business/shared/utils/withToast'
import { DateTime } from 'luxon'
import client from 'services/client'
import {
  DefaultDealEditRequestParams,
  PlatformConfiguration,
  TermsAndConditionsUploadRequest
} from 'services/types/PlatformConfiguration'
import { PlatformFeature } from 'services/types/PlatformFeature'
import { DataTableColumn } from 'shared/components/organisms/data-table'
import { SWRFetcherKey, SWRSuspenseConfiguration } from 'shared/types'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'

export function usePlatformConfiguration() {
  const url = '/platform-configuration'
  const key: SWRFetcherKey = url

  const result = useSWRImmutable<
    PlatformConfiguration,
    string,
    SWRSuspenseConfiguration
  >(key, {
    suspense: true,
    revalidateOnMount: false,
    dedupingInterval: 0
  })

  const { trigger: editDefaultDeal, isMutating: isEditingDefaultDeal } =
    useSWRMutation<void, any, typeof key, DefaultDealEditRequestParams>(
      key,
      async (_, { arg: params }) => {
        const response = await client.put<{ message: string }>(
          '/platform-configuration/edit-default-deal',
          params
        )
        toast(response.data.message, { type: 'success' })
      }
    )

  const isAddonEnabled = (addon: PlatformFeature) =>
    result.data.addons.includes(addon)

  return {
    ...result,
    isEditingDefaultDeal,
    isAddonEnabled,

    error: result.error,

    editDefaultDeal: (
      params: DefaultDealEditRequestParams,
      options?: Parameters<typeof editDefaultDeal>[1]
    ) => editDefaultDeal(params, options),

    formatCurrency: (
      value: Parameters<typeof formatCurrency>[0],
      options?: CurrencyFormatOptions
    ) => formatCurrency(value, { currency: result.data?.currency, ...options }),

    getCurrencySymbol: () =>
      new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: result.data?.currency
      }).formatToParts(0)[0].value,

    formatDate: (date: string | DateTime) =>
      formatDate(
        typeof date === 'string' ? date : date.toISO()!,
        result.data?.timezone!
      ),

    formatDateTime: (date: string | DateTime) =>
      formatDateTime(
        typeof date === 'string' ? date : date.toISO()!,
        result.data?.timezone!
      ),

    getHeader(
      column:
        | DataTableColumn
        | keyof CustomerReportRow
        | keyof AffiliateReportRow
        | keyof ReportRetentionRatios
    ) {
      if (typeof column === 'string') {
        return result.data.headers[column] ?? column
      }

      return column.headerName ?? result.data.headers[column.field] ?? ''
    },
    /**
     * Toggle between two values based on whether the feature is enabled or not.
     *
     * @param addon The addon to check for
     * @param enabledValue The value to return if the addon is enabled
     * @param disabledValue The value to return if the addon is disabled. Default: `undefined`
     */
    withAddon: <E, D = undefined>(
      addon: PlatformFeature,
      enabledValue: E,
      disabledValue: D = undefined as D
    ) => {
      return isAddonEnabled(addon) ? enabledValue : disabledValue
    }
  }
}

export function useTermsAndConditions(
  config?: SWRSuspenseConfiguration<string>
) {
  const url = '/terms-and-conditions'

  const response = useSWRImmutable<string, typeof url, typeof config>(url, {
    suspense: true,
    revalidateOnMount: false,
    ...config
  })

  const { trigger: upload } = useSWRMutation<
    AxiosResponse<string>,
    any,
    string,
    TermsAndConditionsUploadRequest
  >(url, async (_, { arg: { file, description } }) => {
    const fd = new FormData()

    fd.append('file', file, file.name)
    fd.append('description', description || '')

    const res = await client.post('/terms-and-conditions', fd, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    return res.data
  })

  return {
    ...response,
    upload: (
      params: TermsAndConditionsUploadRequest,
      options?: Parameters<typeof upload>[1]
    ) =>
      withToast(
        'Failed to upload Terms & Conditions',
        'Terms & Conditions uploaded successfully'
      )(upload)(params, options)
  }
}
