import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { TermsAndConditionsAgreement } from 'business/shared/terms-and-conditions/TermsAndConditionsAgreement'
import { PublicRoutes } from 'config/routes/routes'
import { makeRoutes, useAllowedUserRoutes } from 'config/routes/support'
import { DateTime, Settings } from 'luxon'
import { usePlatformConfiguration } from 'services/platform-configuration.service'
import { SidebarLayout } from 'shared/components/templates/layout/SidebarLayout'
import Screens from 'shared/screens/Screens'

const SetupTimezone = () => {
  const {
    data: { timezone }
  } = usePlatformConfiguration()

  useEffect(() => {
    // Backend stores timezone in UTC, while on the client side we want to display
    // dates in the preferred timezone, configurd for the platform.
    Settings.defaultZone = timezone || DateTime.utc().zoneName
  }, [timezone])

  return null
}

export const AuthenticatedRoutes = () => {
  const routes = useAllowedUserRoutes()

  return (
    <TermsAndConditionsAgreement>
      <SidebarLayout>
        <SetupTimezone />
        <Routes>
          {makeRoutes(routes)}
          {makeRoutes(PublicRoutes)}
          <Route
            path="/"
            element={
              routes.length ? (
                <Navigate to={routes[0].path} />
              ) : (
                <Screens.NoAccessPermissionPage />
              )
            }
          />
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/register" element={<Navigate to="/" replace />} />
          <Route path="/reset-password" element={<Navigate to="/" replace />} />
          <Route
            path="/forgotten-password"
            element={<Navigate to="/" replace />}
          />
          <Route
            path="*"
            element={
              routes.length ? (
                <Screens.NotFoundPage />
              ) : (
                <Screens.NoAccessPermissionPage />
              )
            }
          />
        </Routes>
      </SidebarLayout>
    </TermsAndConditionsAgreement>
  )
}
